import Button from './Button';
import { InfoPoster } from './InfoPoster';

import { formatNumber } from '../utils/util';
import AnswerPanel from './AnswerPanel';

export default function PageHit({
  price,
  nextPage,
  resetSearch,
  questions,
  onAnswerChange,
  tilstand,
  onTilstandChange,
  modelText,
  form,
}) {
  return (
    <div>
      <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
        <div>
          <InfoPoster />
        </div>
        <div>
          <div className='my-2 text-2xl font-bold text-gray-800'>
            {modelText}
          </div>
          <div className='mt-3 mb-5 text-sm'>
            <span className='text-gray-500'>Reg. nr:</span>{' '}
            {form.regnr.toUpperCase()}
            <br />
            <span className='text-gray-500'>Km. stand:</span>{' '}
            {formatNumber(form.kmstand)}
            <br />
          </div>
          <div>
            <Button
              onClick={resetSearch}
              label='Endre bil'
            />
          </div>

          <div className='my-8'>
            <AnswerPanel
              questions={questions}
              onAnswerChange={onAnswerChange}
              tilstand={tilstand}
              onTilstandChange={onTilstandChange}
            />
          </div>

          <div className='text-lg font-medium'>
            Basert på oppgitt informasjon er estimert markedspris på bilen:
          </div>
          <div className='mt-1 text-3xl font-bold'>
            kr {formatNumber(price.median)}
            <span className='text-sm text-gray-500 font-normal'> median</span>
          </div>
          <div className='text-gray-500'>
            høy: kr {formatNumber(price.max)}, lav: kr {formatNumber(price.min)}
          </div>

          <div className='mt-5 font-bold'>
            Innbyttepris på bilen din vi være den endelige markedsprisen
            fratrukket takserte reparasjoner samt øvrige kostnader og
            fortjeneste.
          </div>

          <div className='mt-8'>
            <Button
              onClick={nextPage}
              label='Kontakt meg med et konkret tilbud'
              largeFont={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
