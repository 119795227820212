import agreeImage from '../images/lexus-talk.png';
import addImage from '../images/lexus-upload.png';

import AreaInput from './AreaInput';
import Button from './Button';
import TextInput from './TextInput';
import {
  isTextValidEmailAddress,
  isTextValidZipCode,
  isTextValidTelephone,
} from '../utils/util';
import { useState } from 'react';

export default function Page2({
  sendContactData,
  form,
  onChange,
  onFileChange,
  images,
  isSending,
}) {
  const [validField, setValidField] = useState({
    name: false,
    zipCode: false,
    email: false,
    telephone: false,
  });
  const [isValid, setIsValid] = useState(false);

  function handleChange(event, isValid) {
    const valid = { ...validField, [event.target.name]: isValid };
    setValidField(valid);
    setIsValid(Object.values(valid).every((el) => el === true));
    onChange(event);
  }

  return (
    <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
      <div className=''>
        <div className='mt-16 mb-4 text-xl'>Kontaktinformasjon</div>

        <div className='grid grid-cols-1 md:grid-cols-3 gap-x-5 mb-4'>
          <div className='md:col-span-2'>
            <TextInput
              label='Navn'
              name='name'
              value={form.name}
              requireValue={true}
              onChange={handleChange}
            />
          </div>
          <div className=''>
            <TextInput
              label='Postnummer'
              name='zipCode'
              value={form.zipCode}
              requireValue={true}
              validator={isTextValidZipCode}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='mb-4'>
          <TextInput
            label='Epost'
            name='email'
            value={form.email}
            requireValue={true}
            validator={isTextValidEmailAddress}
            onChange={handleChange}
          />
        </div>
        <div className='mb-4'>
          <TextInput
            label='Telefon'
            name='telephone'
            value={form.telephone}
            requireValue={true}
            validator={isTextValidTelephone}
            onChange={handleChange}
          />
        </div>

        <div className='mb-6'>
          <AreaInput
            label='Kommentar'
            name='comment'
            value={form.comment}
            onChange={handleChange}
          />
        </div>

        <div className='mt-8 my-4'>
          <div className='font-medium mb-2'>Last opp bilder av bilen</div>
          <div className='flex w-full border border-black bg-toyota-light-gray hover:bg-gray-200'>
            <label
              className='cursor-pointer focus:outline-none w-full py-6'
              htmlFor='image_upload'
            >
              <img
                src={addImage}
                alt='Legg til bilde'
                className='mx-auto'
              />
            </label>
            {images.length > 0
              ? images.map((image, idx) => {
                  return (
                    <img
                      className='max-w-[143]'
                      key={idx}
                      src={image}
                      alt=''
                    />
                  );
                })
              : null}
          </div>
        </div>
        <input
          type='file'
          id='image_upload'
          className='opacity-0'
          onChange={onFileChange}
          accept='image/*'
          multiple
        />

        <div>
          <Button
            disabled={!isValid || isSending}
            label='Send'
            onClick={sendContactData}
            largeFont={true}
          />
        </div>
        <div className='text-xs text-black mt-5 font-extralight w-2/3'>
          Ved å sende denne henvendelsen godtar du våre retningslinjer for
          personvern. Vi er nødt til å samle inn og behandle dine opplysninger
          for å følge opp din henvendelse.
        </div>
      </div>
      <div className='pt-4 mx-20'>
        <div className='flex'>
          <img
            src={agreeImage}
            alt='Logo'
            className='mx-auto'
          />
        </div>
        <div className='mt-8'>
          <h3 className='uppercase text-2xl mb-2 mt-8 flex'>
            Dette skjer videre nå
          </h3>
          <hr className='w-16 h-1px mt-6 mb-10 bg-black border-0 rounded' />

          <div className='text-md mt-3'>
            <p className='mb-4'>
              Takk for at du tok deg tid til å fylle ut skjemaet. For at vi skal
              kunne gi deg en konkret innbyttepris så må vi ta en inspeksjon av
              bilen for å avdekke mulige avvik først før vi tar inn bilen. Dette
              gjør vi for å unngå forsinkelser og misforståelser ved
              innlevering.
            </p>
            <p>
              Du vil bli kontaktet av nærmeste Lexus partner for å avtale en tid
              for inspeksjon. Vi minner om at prisen som du har fått gjennom
              dette verktøyet er en indikasjon på hva vi tror bilen er verdt på
              det åpne markedet for øyeblikket. Ved innbytte så vil vi tilby en
              pris som vil være lavere, dette fordi vi må kalkulere våre
              kostnader i forbindelse med salget av ditt kjøretøy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
