import { useContext } from 'react';
import { ReturnUrlContext } from '../contexts/ReturnUrlContext';

export default function Page3() {
  const returnUrl = useContext(ReturnUrlContext);

  return (
    <div className='mt-36'>
      <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
        <div className=''></div>
        <div className=''>
          <h3 className='uppercase text-2xl mb-2 mt-8 flex'>
            {' '}
            Kontaktinformasjon er innsendt{' '}
          </h3>
          <hr className='w-16 h-1px mt-6 mb-10 bg-black border-0 rounded' />

          <div className='mt-3 mb-8 text-md'>
            Du vil motta bekreftelse på epost om innsendt kontaktskjema.
            Forespørselen vil bli sendt til rett forhandler etter postnummer.
            Når henvendelsen er mottatt vil du bli kontaktet av en forhandler.
          </div>
          <div className='underline'>
            {returnUrl && <a href={returnUrl}>Tilbake til Lexus.no</a>}
          </div>
        </div>
      </div>
    </div>
  );
}
