import Button from './Button';
import TextInput from './TextInput';
import { useState } from 'react';

import { isInteger, isTextValidRegNumber } from '../utils/util';
import AnswerPanel from './AnswerPanel.jsx';

export default function Page1({
  form,
  onChange,
  onSearchMarketplacePrices,
  questions,
  onAnswerChange,
  tilstand,
  onTilstandChange,
}) {
  const [validField, setValidField] = useState({
    regnr: false,
    kmstand: false,
  });
  const [isValid, setIsValid] = useState(false);

  function handleChange(event, isValid) {
    const valid = { ...validField, [event.target.name]: isValid };
    setValidField(valid);
    setIsValid(Object.values(valid).every((el) => el === true));
    onChange(event);
  }

  return (
    <div>
      <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
        <div className='px-24'>
          <div className='uppercase mt-8 mb-8 text-4xl'>
            Selg din bil til oss
          </div>
          <hr className='w-16 h-1px my-4 bg-black border-0 rounded md:my-10' />
          <div className='mb-12 text-2xl text-black'>
            Vi gjør dette enkelt for deg, smidig og profesjonelt
          </div>
        </div>
        <div>
          <div className='mt-12 mb-10 text-lg'>
            For best mulig estimat på markedspris, oppgi mest mulig informasjon
          </div>
          <div className='grid grid-cols-1 gap-x-5 sm:grid-cols-2'>
            <div className=''>
              <TextInput
                label='Reg.nr'
                name='regnr'
                value={form.regnr}
                requireValue={true}
                validator={isTextValidRegNumber}
                onChange={handleChange}
              />
            </div>
            <div className=''>
              <TextInput
                label='Kilometerstand'
                name='kmstand'
                requireValue={true}
                value={form.kmstand}
                validator={isInteger}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='my-8'>
            <AnswerPanel
              questions={questions}
              onAnswerChange={onAnswerChange}
              tilstand={tilstand}
              onTilstandChange={onTilstandChange}
            />
          </div>

          <div>
            <Button
              onClick={() => onSearchMarketplacePrices()}
              disabled={!isValid}
              label='Hent markedspris'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
