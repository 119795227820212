const apiKey = process.env['API_KEY'];
const defaultReturnUrl = process.env['DEFAULT_RETURN_URL'];

export const store = {
  questions: [],
  regNumber: '',
  mileage: '0',
  model: '',
  userPhone: '',
  userName: '',
  userEmail: '',
  zipCode: '',
};

export const configuration = {
  apiKey: apiKey,
  defaultReturnUrl: defaultReturnUrl,
  tilstandId: parseInt(process.env['TILSTAND_ID']),
};
