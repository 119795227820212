import 'whatwg-fetch';

const { API_HOST } = process.env;

const doFetch = (url, options) => {
  if (!options) {
    options = {};
  }
  options.method = options.method || 'GET';
  let params = {
    method: options.method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (options.method !== 'HEAD' && options.method !== 'GET') {
    params['body'] = JSON.stringify(options.body || {});
  }
  return fetch(url, params).then((r) => r.json());
};

export const postFormData = (token, formData) => {
  const url = API_HOST + 'widgets/storemultipart?token=' + token;

  return fetch(url, {
    method: 'POST',
    body: formData,
  }).then((res) => {
    console.log(res);
    if (res.ok) {
      return res.json();
    }
    throw new Error('Network response was not ok.');
  });
};

export const getQuestionList = (token) => {
  return doFetch(API_HOST + 'widgetquestions/get?token=' + token + '');
};

export const getPrice = (token, regNumber, mileage, uid) => {
  const p = `?token=${token}&regnumber=${regNumber}&mileage=${mileage}&uid=${uid}`;
  return doFetch(`${API_HOST}widgets/price` + p);
};

export const postUserData = (token, contactData) => {
  return doFetch(API_HOST + 'widgets/storerequest?token=' + token, {
    method: 'POST',
    body: contactData,
  });
};
