import arrow from '../images/lexus-arrow.png';

export default function Button({
  label,
  onClick,
  disabled = false,
  largeFont = false,
}) {
  let font = largeFont ? 'text-lg' : 'text-md';

  let className = `group w-full uppercase inline-flex flex-row justify-between items-center px-4 py-3 border border-transparent ${font} font-medium
                shadow-sm drop-shadow-lg text-white bg-toyota-gray hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black
                 disabled:opacity-50 disabled:hover:bg-lexus-gray`;

  return (
    <button
      type='button'
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      {label}
      <img
        className='float-right transition transform group-hover:-translate-x-2 ease-in-out delay-150'
        src={arrow}
        alt='Info'
      />
    </button>
  );
}
