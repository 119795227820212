import CarImage from '../images/lexus-car.png';

export function InfoPoster() {
  return (
    <div>
      <div className='flex flex-col-2 justify-evenly my-4'>
        <img
          src={CarImage}
          alt='Bil'
        />
      </div>
      <div className='mt-8 text-2xl font-bold'>
        Dette påvirker markedsprisen på bilen din
      </div>
      <div className='mb-8'>
        Det er mange faktorer som må tas i betraktning når vi skal finne riktig
        pris for en bruktbil. Alle bruktbiler er forskjellige, og noen er mer
        ettertraktet enn andre. Dette varierer og påvirker blant annet hvor lang
        tid det tar å selge en bruktbil. Utstyrsnivå, farge, drivlinje, tilstand
        på bil og dekk er viktige faktorer som vil bestemme hvor attraktiv bilen
        er.
        <p className='font-bold'>
          Vårt verktøy vil ta dette i betraktning og estimere en pris som vi
          mener er riktig markedspris på det åpne markedet.
        </p>
        <p className='my-3'>
          Markedsprisen er grunnlaget for å kalkulere innbytteprisen
          (markedspris minus reparasjoner, kostnader og fortjeneste).
        </p>
        <p className='my-3 font-bold'>
          Verktøyet vil IKKE gi deg en konkret pris på ditt kjøretøy, kun en
          indikasjon ved å sammenligne med tilsvarende annonserte og solgte
          biler.
        </p>
        <p className='my-3 italic'>
          Dersom verktøyet ikke klarer å hente ut tilstrekkelig med informasjon
          til å estimere markedspris automatisk, ønsker vi likevel at du fyller
          ut og sender inn kontaktskjema. Vi vil da foreta en manuell vurdering
          av ditt kjøretøy. Om vår indikasjon høres bra ut, vil du bli invitert
          til forhandler for inspeksjon og bli tilbudt en endelig innbyttepris.
        </p>
      </div>
    </div>
  );
}
