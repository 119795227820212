import nextId from 'react-id-generator';
import { useState } from 'react';

export default function TextInput({
  label,
  name,
  value,
  onChange,
  requireValue,
  validator = null,
}) {
  const htmlId = nextId();
  const [isValid, setIsValid] = useState(checkValid(value));

  const handleChange = (event) => {
    const valid = checkValid(event.target.value);
    setIsValid(valid);
    onChange(event, valid);
  };

  function checkValid(value) {
    if (!requireValue) return true;
    if (validator) return validator(value);
    return value !== '';
  }

  return (
    <div>
      <label
        htmlFor={htmlId}
        className='block text-sm font-medium text-gray-700 sm:mt-px pt-2 pb-2'
      >
        {label}
        {!isValid && <span className='ml-1 text-red-500 font-bold'>*</span>}
      </label>
      <div className='mt-1 sm:col-span-2 sm:mt-0'>
        <input
          type='text'
          name={name}
          id={htmlId}
          autoComplete='given-name'
          onChange={handleChange}
          value={value}
          className='text-sm border border-black block w-full pl-3.5 py-3
            toyota_type-book outline-none focus:border-toyota-gray focus:shadow-md focus:ring-black'
        />
      </div>
    </div>
  );
}
